<template>
    <div class="article-panel taskdata-panel">
        <div class="taskdata-panel-inner">
            <h3 class="g-title">项目成果</h3>
            <div class="article-bd">
                <!-- <div class="task-info">
                    <div class="taskinfo-l">
                        <div class="tit-box">
                            <span class="status-box">{{trade.status_value}}</span>
                            <h4 class="tit" v-text="task.task_name"></h4>
                        </div>
                        <div class="num-box">
                            <div class="time-box">
                                <span class="desc">
                                    <i class="el-icon-time"></i>
                                    发布时间：
                                    <i
                                        class="num"
                                    >{{task.approve_time}}</i>
                                </span>
                                <span class="desc">
                                    报名人数：
                                    <i class="num">{{task.enroll_count}}</i>人
                                </span>
                            </div>
                            <div class="reword-box">
                                <div class="reword-item">
                                    <span class="desc">酬金：</span>
                                    <b class="num">￥{{trade.remuneration}}</b>
                                </div>
                                <div class="reword-item" v-if="task.task_type==3">
                                    <span class="desc">工期：</span>
                                    <b class="num">{{trade.work_time}}</b>
                                </div>
                                <div class="reword-item" v-if="task.task_type==3">
                                    <span class="desc">每天酬金：</span>
                                    <b
                                        class="num"
                                    >￥{{(trade.remuneration/trade.work_time).toFixed(2)}}</b>
                                </div>
                            </div>
                        </div>
                        <div class="userinfo-box" v-if="task.employer">
                            <div class="user-l">
                                <el-image :src="util.reImg(task.employer.avatar)">
                                    <div class="err-slot image-slot" slot="error">
                                        <span class="placeholder"></span>
                                    </div>
                                </el-image>
                            </div>
                            <div class="user-r">
                                <div class="desc-item">{{task.employer.name}}</div>
                                <div class="desc-item">项目类别：{{task.task_type_value}}</div>
                                <div class="desc-item">
                                    信用评分：
                                    <el-rate
                                        :value="parseFloat(task.employer.credence)"
                                        disabled
                                        show-score
                                        text-color="#ef8000"
                                        score-template="{value}"
                                    ></el-rate>
                                </div>
                                <div class="desc-item">服务分类：{{task.speciality_type_value}}</div>
                            </div>
                        </div>
                    </div>
                    <div class="taskinfo-r">
                        <div class="user-info-box" v-if="trade.geek">
                            <h4 class="tit">中标极客</h4>
                            <div class="avatar">
                                <el-image :src="util.reImg(trade.geek.avatar)" :fit="'fill'">
                                    <div class="err-slot image-slot" slot="error">
                                        <span class="placeholder"></span>
                                    </div>
                                </el-image>
                                <div class="avatar-r">
                                    <p class="desc">{{trade.geek.name}}</p>
                                    <span class="desc">{{trade.geek.area_value}}</span>
                                </div>
                            </div>
                            <div class="user-info-ft" v-if="userInfo.info.user_type==2">
                                <el-button type="primary" @click="sendMessage(trade.geek.uk)">发信息</el-button>

                                <el-button
                                    type="primary"
                                    @click="keep(trade.geek.uk)"
                                    v-if="is_subscribe==0"
                                >关注Ta</el-button>
                                <el-button
                                    type="primary"
                                    @click="keep(trade.geek.uk)"
                                    style="opacity:.5"
                                    v-else
                                >已关注</el-button>
                            </div>

                            <div class="user-info-ft" v-else>
                                <el-button
                                    type="primary"
                                    disabled
                                    @click="sendMessage(trade.geek.uk)"
                                >发信息</el-button>

                                <el-button
                                    type="primary"
                                    @click="keep(trade.geek.uk)"
                                    disabled
                                    v-if="is_subscribe==0"
                                >关注Ta</el-button>
                                <el-button
                                    type="primary"
                                    disabled
                                    @click="keep(trade.geek.uk)"
                                    style="opacity:.5"
                                    v-else
                                >已关注</el-button>
                            </div>
                        </div>
                    </div>
                </div>-->

                <div class="data-list-wraper mt-20">
                    <div class="td-item-box step1-box step4-box">
                        <div class="br-bd">
                            <!-- 雇主查看项目成果时间轴 start -->
                            <el-timeline v-if="userInfo.info.user_type==2">
                                <el-timeline-item
                                    :timestamp="updateTime"
                                    placement="top"
                                >
                                    <el-card>
                                        <div class="card-bd">
                                            <div class="cardbd-l">
                                                <h4
                                                    class="tit mb-10"
                                                    v-text="block.title"
                                                ></h4>
                                                <p
                                                    class="mb-10"
                                                    v-if="!block.annex"
                                                >进度：阶段工作进行中</p>
                                                <p
                                                    class="mb-10"
                                                    v-else
                                                >
                                                    进度：已提交阶段成果（
                                                    <a
                                                        :href="websiteConfigs.sourceUrl+block.annex_zip"
                                                        target="_blank"
                                                    >打包下载</a>）
                                                </p>
                                                <p
                                                    class="mb-10"
                                                    v-if="!block.annex"
                                                >极客尚未提交成果</p>
                                                <div class="filist-list">
                                                    <span
                                                        v-for="(item,index) in block.annex"
                                                        :key="index"
                                                    >
                                                        <el-tooltip
                                                            class="item"
                                                            effect="dark"
                                                            placement="top"
                                                        >
                                                            <div
                                                                slot="content"
                                                                style="text-align:center;"
                                                            >
                                                                {{item.name}}
                                                                <br />点击查看
                                                            </div>

                                                            <a
                                                                target="_Blank"
                                                                href="javascript:;"
                                                                @click="view(item.url)"
                                                                class="file-item icon-ppt"
                                                                :style="{backgroundImage:'url('+$img.icon_png+')',backgroundRepeat:'none',backgroundPosition:'center center'}"
                                                            ></a>

                                                        </el-tooltip>
                                                    </span>
                                                </div>
                                            </div>
                                            <div
                                                style="margin-top:20px"
                                                v-if="block.status>=1 && block.description"
                                            >成果描述：{{block.description}}</div>
                                        </div>
                                    </el-card>
                                </el-timeline-item>
                            </el-timeline>
                            <!-- 雇主查看项目成果时间轴 end -->

                            <!-- 极客上传项目阶段成果时间轴 start -->
                            <el-timeline
                                class="jk-timeline"
                                v-if="userInfo.info.user_type==1 && block.status<=2"
                            >
                                <!-- 极客还未上传阶段成果 提示在规定时间上传阶段成果 start  -->
                                <el-timeline-item
                                    class="active"
                                    :timestamp="deliveryTime"
                                    placement="top"
                                    v-if="!block.annex"
                                >
                                    <el-card>
                                        <div class="card-bd">
                                            <div class="cardbd-l">
                                                <h4
                                                    class="tit mb-10"
                                                    v-text="block.title"
                                                ></h4>
                                                <p class="mb-10">您还未上传阶段成果，请在截止日期前上传您的阶段成果</p>
                                                <upload-annex v-model="block.annex"></upload-annex>
                                                <div class="el-upload__tip">您可以上传容量小于15MB，格式为jpg、png、gif，bmp的图片文件，格式为doc,docx,pdf的文本文件，或格式为rar, 7z的压缩文件。</div>
                                            </div>
                                        </div>
                                    </el-card>
                                </el-timeline-item>
                                <!-- 极客还未上传阶段成果 提示在规定时间上传阶段成果 end  -->

                                <!-- 极客已上传阶段成果 如未到结束时间，可继续上传阶段成果  start   -->
                                <el-timeline-item
                                    class="active"
                                    :timestamp="deliveryTime"
                                    placement="top"
                                    v-else
                                >
                                    <el-card>
                                        <div class="card-bd">
                                            <div class="cardbd-l">
                                                <h4
                                                    class="tit mb-10"
                                                    v-text="block.title"
                                                ></h4>
                                                <p
                                                    class="mb-10"
                                                    v-if="block.annex && block.annex.length>=8"
                                                >成果附件个数最多只能上传8个，现已达到最大量。</p>
                                                <p
                                                    class="mb-10"
                                                    v-else
                                                >你已上传阶段成果，如需补充可继续上传</p>
                                                <upload-annex v-model="block.annex"></upload-annex>
                                                <div class="el-upload__tip">您可以上传容量小于15MB，格式为jpg、png、gif，bmp的图片文件，格式为doc,docx,pdf的文本文件，或格式为rar, 7z的压缩文件。</div>
                                            </div>
                                            <div
                                                style="margin-top:10px;"
                                                v-if="block.description"
                                            >
                                                <span>成果描述：{{block.description}}</span>
                                            </div>
                                            <div style="margin-top:10px;">
                                                <el-button
                                                    @click="descDialog=true"
                                                    v-if="block.description"
                                                >修改描述</el-button>
                                                <el-button
                                                    @click="descDialog=true"
                                                    v-else
                                                >添加描述</el-button>
                                            </div>
                                        </div>
                                    </el-card>
                                </el-timeline-item>
                                <!-- 极客已上传阶段成果 如未到结束时间，可继续上传阶段成果  end   -->
                            </el-timeline>

                            <el-timeline v-if="userInfo.info.user_type==1 && block.status==3">
                                <el-timeline-item
                                    :timestamp="updateTime"
                                    placement="top"
                                >
                                    <el-card>
                                        <div class="card-bd">
                                            <div class="cardbd-l">
                                                <h4
                                                    class="tit mb-10"
                                                    v-text="block.title"
                                                ></h4>
                                                <p
                                                    class="mb-10"
                                                    v-if="!block.annex"
                                                >进度：阶段工作已开始</p>
                                                <p
                                                    class="mb-10"
                                                    v-else
                                                >
                                                    进度：已提交阶段成果（
                                                    <a
                                                        :href="websiteConfigs.sourceUrl+block.annex_zip"
                                                        target="_blank"
                                                    >打包下载</a>）
                                                </p>
                                                <div class="filist-list">
                                                    <span
                                                        v-for="(item,index) in block.annex"
                                                        :key="index"
                                                    >
                                                        <el-tooltip
                                                            class="item"
                                                            effect="dark"
                                                            placement="top"
                                                        >
                                                            <div
                                                                slot="content"
                                                                style="text-align:center;"
                                                            >
                                                                {{item.name}}
                                                                <br />点击查看
                                                            </div>
                                                            <a
                                                                target="_Blank"
                                                                href="javascript:;"
                                                                @click="view(item.url)"
                                                                class="file-item icon-ppt"
                                                                :style="{backgroundImage:'url('+$img.icon_png+')',backgroundRepeat:'none',backgroundPosition:'center center'}"
                                                            ></a>
                                                        </el-tooltip>
                                                    </span>
                                                </div>
                                            </div>
                                            <div style="margin-top:20px">成果描述：{{block.description}}</div>
                                        </div>
                                    </el-card>
                                </el-timeline-item>
                            </el-timeline>
                            <!-- 极客上传项目阶段成果时间轴 end -->

                            <div style="width:100%;text-align:center;height:80px;line-height:80px;">
                                <el-button
                                    @click="submit"
                                    v-if="userInfo.info.user_type==1 && block.status != 3"
                                >提交</el-button>
                                <el-button @click="back">返回</el-button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <el-dialog
            title="添加描述"
            :visible.sync="descDialog"
        >
            <el-form :model="form">
                <el-form-item>
                    <el-input
                        :rows="5"
                        type="textarea"
                        v-model="block.description"
                        maxlength="1000"
                        placeholder="请填写阶段成果描述内容"
                        autocomplete="off"
                    ></el-input>
                </el-form-item>
                <el-form-item style="text-align:center;">
                    <el-button
                        type="primary"
                        @click="descDialog = false"
                    >确定</el-button>
                </el-form-item>
            </el-form>
        </el-dialog>
    </div>
</template>
<script>
import { mapActions, mapState } from "vuex";
import UploadAnnex from "@/components/upload/UploadAnnex.vue";
export default {
    name: "taskResults",
    metaInfo() {
        return {
            title: "项目成果 - 询龙网",
            meta: [
                {
                    name: "keywords",
                    content: ""
                }, {
                    name: "description",
                    content: ''
                }
            ]
        }
    },
    components: {
        UploadAnnex //上传附件组件
    },
    data() {
        return {
            employeeScore: 3.7, // 雇主信用评分
            uploadListData: [], //附件列表
            task: "", //项目详情
            block: {
                annex: "",
                description: ""
            }, //分段详情
            trade: "", //邀请信息
            is_subscribe: 0, //是否关注
            deliveryTime: "成果交付截止日期：",
            updateTime: "成果提交时间：", //成果提交时间
            descDialog: false, //文字描述对话框
            form: ""
        };
    },
    computed: {
        ...mapState(["userInfo"])
    },
    methods: {
        //获取项目信息
        loadData: function () {
            let _this = this;
            _this.post(
                "/task/task/detail_todo",
                { tk: _this.$route.query.tk },
                res => {
                    if (res.code == 200) {
                        _this.task = res.data;
                        _this.trade = res.data.trade;
                        _this.deliveryTime =
                            "成果交付截止日期：" + res.data.trade.delivery_time;
                    } else {
                        if (res.msg) {
                            _this.$message.success(res.msg);
                        } else {
                            _this.$message.success("获取失败");
                        }
                    }
                }
            );
        },

        //获取分段信息
        blockDetail: function () {
            let _this = this;
            _this.post(
                "/task/task/block_one",
                { tk: _this.$route.query.tk, block_id: _this.$route.query.id },
                res => {
                    if (res.code == 200) {
                        _this.block = res.data;
                        _this.updateTime =
                            "成果提交时间：" + res.data.update_time;
                    } else {
                        if (res.msg) {
                            _this.$message.success(res.msg);
                        } else {
                            _this.$message.success("获取失败");
                        }
                    }
                }
            );
        },

        //发送消息
        sendMessage(val) {
            //侧边栏是否保持展开
            if (val && val.isShowDraw == "isShowDraw") {
                sessionStorage.setItem("isShowDraw", true);
            }
            sessionStorage.setItem(
                "currentPageUrl",
                document.location.pathname + document.location.search
            ); //存储当前值
            this.$router.push("/user/message/sendmsg?uk=" + val);
        },

        //是否关注作者
        subscribe() {
            let _this = this;
            let uk = "";
            if (_this.trade && _this.trade.geek.uk) {
                uk = _this.trade.geek.uk;
            }
            this.post(
                "/user/index/is_subscribe",
                {
                    uk: uk
                },
                function (res) {
                    if (res.code == 200) {
                        if (res.data.is_subscribe == 1) {
                            _this.is_subscribe = 1;
                        } else {
                            _this.is_subscribe = 0;
                        }
                    } else {
                        _this.$message.error(res.msg);
                    }
                }
            );
        },

        //关注用户
        keep(uk) {
            let _this = this;
            this.post(
                "/user/user/collect_user",
                {
                    uk: uk
                },
                function (res) {
                    if (res.code == 200) {
                        if (res.data == 1) {
                            //关注成功
                            _this.is_subscribe = 1;
                            _this.$message({
                                message: res.msg,
                                type: "success"
                            });
                        } else {
                            //取消关注
                            _this.is_subscribe = 0;
                            _this.$message({
                                message: res.msg,
                                type: "success"
                            });
                        }
                    } else {
                        _this.$message.error(res.msg);
                    }
                }
            );
        },

        //更新阶段成果
        submit() {
            let _this = this;
            if (!_this.block.annex || _this.block.annex == '') {
                _this.$message.success("请先上传成果信息");
                return false;
            }
            _this.post(
                "/task/task/block_update",
                {
                    tk: _this.$route.query.tk,
                    block_id: _this.$route.query.id,
                    annex: _this.block.annex,
                    description: _this.block.description
                },
                res => {
                    if (res.code == 200) {
                        _this.descDialog = false;
                        _this.$message.success("操作成功");
                        if (
                            _this.block.annex &&
                            _this.block.annex.length <= 0
                        ) {
                            _this.blockDetail();
                        }
                    } else {
                        if (res.msg) {
                            _this.$message.success(res.msg);
                        } else {
                            _this.$message.success("操作失败");
                        }
                    }
                }
            );
        },

        //查看或下载附件
        view(url) {
            window.open(this.websiteConfigs.sourceUrl + url, "_blank");
        },

        //返回
        back() {
            this.$router.go(-1); //返回上一层
        }
    },
    created() {
        this.loadData(); //获取项目详情
        this.blockDetail(); //获取分段详情
        this.subscribe(); //查询是否已关注
    }
};
</script>

<style lang="less" scroped>
@import "../../../styles/taskData.less";
.el-timeline {
    .tit {
        margin-top: 0;
    }
}
.filist-list {
    font-size: 0;
    margin: 0 -10px;

    .file-item {
        display: inline-block;
        width: 80px;
        height: 80px;
        margin: 0 5px;
        border: 1px solid #f0f0f0;
        background-size: 47px 60px !important;
        background-repeat: no-repeat !important;
        background-position: center center !important;

        &:hover {
            border-color: #587ddc;
        }
    }

    .icon-ppt {
        background: url("~@/assets/img/icon_doc.png") no-repeat center center;
    }

    .icon-doc {
        background: url("~@/assets/img/icon_jpg.png") no-repeat center center;
    }

    .icon-xls {
        background: url("~@/assets/img/icon_pdf.png") no-repeat center center;
    }

    .icon-jpg {
        background: url("~@/assets/img/icon_png.png") no-repeat center center;
    }

    .icon-png {
        background: url("~@/assets/img/icon_ppt.png") no-repeat center center;
    }

    .icon-pdf {
        background: url("~@/assets/img/icon_rar.png") no-repeat center center;
    }

    .icon-zip {
        background: url("~@/assets/img/icon_xls.png") no-repeat center center;
    }

    .icon-rar {
        background: url("~@/assets/img/icon_zip.png") no-repeat center center;
    }
}
.jk-timeline {
    .el-timeline-item {
        padding: 20px 0;
        .tit {
            color: #666;
        }
        &.active {
            background: white;
            .el-card__body {
                background: white;
            }
            .tit {
                color: #333;
            }
        }
    }
    .el-card__body {
        background: #f0f0f0;
    }
}
</style>